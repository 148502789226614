<template>
  <transition name="fade-in">
    <p-overlay>
      <transition appear name="p-bottom-sheet-slide">
        <div v-click-out="handleClickOut" class="p-bottom-sheet">
          <template v-if="hasHeader">
            <p-drawer-header
              :title="title"
              :description="description"
              :back="showBack"
              :close="showClose"
              @on-back="emit('on-back')"
              @on-close="handleClose"
            >
              <template #actions>
                <div v-if="clear" @click.stop="emit('on-clear')">
                  <p-button button-type="ghost">
                    <p
                      class="f-utility-14 t-passive-states-disabled-fg"
                      v-text="$t('general.clear')"
                    />
                  </p-button>
                </div>
              </template>
            </p-drawer-header>
          </template>
          <div :class="contentClass">
            <slot />
          </div>
        </div>
      </transition>
    </p-overlay>
  </transition>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close', 'on-back', 'on-clear'])

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  showClose: {
    type: Boolean,
    default: false,
  },
  showBack: {
    type: Boolean,
    default: false,
  },
  clear: {
    type: Boolean,
    default: false,
  },
})

const isMounted = ref(false)
const hasHeader = props.showBack || props.showClose || props.title || props.description

const contentClass = computed(() => {
  return ['p-bottom-sheet__content', { 'p-bottom-sheet__content--header': hasHeader }]
})

function handleClose() {
  if (!props.persistent) {
    emit('on-close')
  }
}

function handleClickOut() {
  if (!props.persistent && isMounted.value) {
    emit('on-close')
  }
}

onMounted(() => {
  setTimeout(() => {
    isMounted.value = true
  }, 0)
})
</script>
